export const fadeInUp = {
	initial: {
		opacity: 0,
		y: "100px",
	},
	in: {
		opacity: 1,
		y: 0,
	},
	out: {
		opacity: 0,
		y: "-100px",
	}
};

export const fadeInDown = {
	initial: {
		opacity: 0,
		y: "-100px",
	},
	in: {
		opacity: 1,
		y: 0,
	},
	out: {
		opacity: 0,
		y: "100px",
	}
};

export const slow = {
	type: "spring",
	ease: "anticipate",
	duration: 1
};