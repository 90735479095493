import React from 'react';
import { motion } from "framer-motion";
import { fadeInUp, fadeInDown, slow } from "./Animations.js";
import logo from './820_Naval_Air_Squadron_Crest.png';
import map from './falklands-map.png';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.state = { isLoggedIn: false };
  }

  handleLoginClick() {
    this.setState({ isLoggedIn: true });
  }

  handleCloseClick() {
    this.setState({ isLoggedIn: false });
  }

  render() {
    const isLoggedIn = this.state.isLoggedIn;
    let button;

    if (isLoggedIn) {
      button = <CloseButton onClick={this.handleCloseClick} />;
    } else {
      button = <LoginButton onClick={this.handleLoginClick} />;
    }

    return (

      <div className="bg-navy relative min-h-screen flex flex-col">
        <header className="flex p-6 lg:p-12 justify-between items-center z-50 relative mb-0 xl:mb-6">
          <motion.img
            initial="initial"
            animate="in"
            exit="out"
            variants={fadeInDown}
            transition={slow} className="w-24 xl:w-48" src={logo} alt="logo" />
          {button}
        </header>
        <main className="flex p-6 lg:p-12 justify-center z-50 relative">
          <Greeting isLoggedIn={isLoggedIn} />
        </main>
        <img className="object-cover h-screen object-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-10" src={map} alt="map" />
      </div>
    );
  }
}

export default App;

function UserGreeting(props) {
  return <motion.div
    initial="initial"
    animate="in"
    exit="out"
    variants={fadeInUp}
    transition={slow} className="bg-white bg-opacity-70 backdrop-filter backdrop-blur-sm shadow-2xl rounded p-8 xl:p-12 w-full lg:w-8/12 xl:w-6/12 2xl:w-5/12">
    <h1 className="font-bold text-lg xl:text-3xl text-navy mb-8">Login</h1>
    <div className="flex flex-col">
      <input className="p-4 mb-4 rounded" type="email" placeholder="Email" />
      <input className="p-4 mb-4 rounded" type="password" placeholder="Password" />
      <input className="p-4 rounded bg-cream font-bold uppercase active:bg-red active:text-white transition duration-300 cursor-pointer" type="submit" placeholder="Login" />
    </div>
  </motion.div>;
}

function GuestGreeting(props) {
  return <motion.div
    initial="initial"
    animate="in"
    exit="out"
    variants={fadeInUp}
    transition={slow} className="bg-white bg-opacity-70 backdrop-filter backdrop-blur-sm shadow-2xl rounded p-8 xl:p-12 w-full lg:w-10/12 xl:w-9/12 2xl:w-7/12">
    <h1 className="font-bold text-lg xl:text-3xl text-navy mb-8">The 820 Ringbolt Association</h1>
    <p className="mb-8 font-light text-sm xl:text-base">820 Naval Air Squadron (NAS) was embarked in HMS INVINCIBLE flying Sea King HAS Mk5 during the Falklands campaign of 1982. The Squadron which, with the Sea Harriers of 801 NAS, formed HMS INVINCIBLE’s Air Group, comprised 50 officers and 151 Senior and Junior Ratings.</p>
    <p className="mb-8 font-light text-sm xl:text-base">HMS INVINCIBLE’s role in the successful recovery of the Falkland Islands is well documented, including setting records for continuous aircraft carrier operations. Following the campaign, the officers of 820 Squadron formed the 820 Ringbolt Association. The association has met biennially nineteen times since 1982 to mark this key event in our country’s history. In recent years, it has embraced 801 Squadron and the Ship’s aviation team. We regularly muster over 70 people to our gatherings with members returning specifically for the occasion from as far afield as Australia and the USA.</p>
    <p className="font-bold text-sm xl:text-base">2022, of course, marks the 40th anniversary of the Falklands Campaign.</p>
  </motion.div>;
}

function Greeting(props) {
  const isLoggedIn = props.isLoggedIn;
  if (isLoggedIn) {
    return <UserGreeting />;
  }
  return <GuestGreeting />;
}

function LoginButton(props) {
  return (
    <motion.button
      initial="initial"
      animate="in"
      exit="out"
      variants={fadeInDown}
      transition={slow} className="bg-cream p-4 font-bold uppercase rounded text-xs xl:text-sm shadow-lg hover:bg-white" onClick={props.onClick}>
      Members Area
    </motion.button>
  );
}

function CloseButton(props) {
  return (
    <motion.button
      initial="initial"
      animate="in"
      exit="out"
      variants={fadeInDown}
      transition={slow} className="bg-cream p-4 font-bold uppercase rounded text-xs xl:text-sm shadow-lg hover:bg-white" onClick={props.onClick}>
      Home
    </motion.button>
  );
}